import * as OutsideClickHandler from '../Common/OutsideClickHandler';
import MediaPlayer from './MediaPlayer';

export default function MediaPlayerWrapper({ enrichment, hideMediaPlayerCallback }) {
    const mediaPlayerParentElementId = "media_player_parent";
    const mediaPlayerLayoutElementId = "media_player_layout";

    const mediaPlayerFinishedLoadingCallback = () => {
        OutsideClickHandler.setOutsideClickListener(mediaPlayerParentElementId, mediaPlayerLayoutElementId, hideMediaPlayerCallback);
    }

    const closeMediaPlayerCallback = () => {
        OutsideClickHandler.removeOutsideClickListener();
        if(hideMediaPlayerCallback) {
            hideMediaPlayerCallback();
        }
    }

    return (
        <div id="media_player_parent" className="modal-background">
            <div id="media_player_layout" className="media-player-layout">
                <MediaPlayer
                    enrichment={enrichment}
                    mediaPlayerFinishedLoadingCallback={mediaPlayerFinishedLoadingCallback}
                    closeMediaPlayerCallback={closeMediaPlayerCallback} />
            </div>
       </div>
    );
}