class ExperienceState {
    selectedExperience;

    constructor() {
        if(ExperienceState.instance) {
            return ExperienceState.instance;
        }
        ExperienceState.instance = this;
    }

    static getInstance = () => {
        if(ExperienceState.instance) {
            return ExperienceState.instance;
        }
        return new ExperienceState();
    }

    getSelectedExperience() {
        return this.selectedExperience;
    }

    setSelectedExperience(experienceMetaData) {
        this.selectedExperience = experienceMetaData;
    }
}

export default ExperienceState;