import EnrichmentType from '../Util/EnrichmentType';
import MiscConstants from '../Util/MiscConstants';
import AppConfig from '../AppConfig';
import ExperienceState from '../State/ExperienceState';
import * as AnalyticsManager from '../Analytics/AnalyticsManager';
import ExperienceMetadata from '../dto/ExperienceMetadata';

const downloadExperienceUid = new URLSearchParams(window.location.search).get(MiscConstants.urlParamDownloadExperienceUid);
const quizUid = new URLSearchParams(window.location.search).get(MiscConstants.urlParamQuizUid);

const processStatus = (response) => {
    if (response.status === 200 || response.status === 0) {
        return Promise.resolve(response);
    } else {
        return Promise.reject(new Error(response.statusText));
    }
};

const downloadAssetBundleMetadata = async (assetBundleUid) => {
    let getAssetBundleURL = AppConfig.getInstance().getAssetBundleAPI() + assetBundleUid;
    const response = await fetch(getAssetBundleURL).then(processStatus);
    const responseJson = await response.json();
    return responseJson;
}

const downloadAssetBundleMetadatas = async (downloadedEnrichments, setEnrichmentsCallback, setIsLoadingCallback) => {
    if(setEnrichmentsCallback) {
        let index = 0;
        for(const enrichment of downloadedEnrichments) {
            if(enrichment.type
                && EnrichmentType.Model === enrichment.type
                && enrichment.assetBundleUid
                && enrichment.assetBundleUid !== MiscConstants.emptyUUID) {
                let assetBundleMetadata = await downloadAssetBundleMetadata(enrichment.assetBundleUid);
                if(!enrichment.name) {
                    downloadedEnrichments[index].name = assetBundleMetadata.name;
                }
                if(assetBundleMetadata.boundingBox) {
                    downloadedEnrichments[index].boundingBoxMinY = assetBundleMetadata.boundingBox.minY;
                    downloadedEnrichments[index].boundingBoxMaxY = assetBundleMetadata.boundingBox.maxY;
                }
            }
            index++;
        }
        setEnrichmentsCallback(downloadedEnrichments);
    }
    setIsLoadingCallback(false);
    AnalyticsManager.logStartOpenExperienceEvent();
    AnalyticsManager.logFinishedOpenExperienceEvent();
};

const processQuizEnrichment = async (augmentations, setQuizEnrichmentCallback, setIsLoadingCallback) => {
    for(let augmentation of augmentations) {
        if(augmentation.enrichments) {
            for(let enrichment of augmentation.enrichments) {
                if(enrichment.type) {
                    if(EnrichmentType.Quiz === enrichment.type) {
                        if(!enrichment.name) {
                            enrichment.name = enrichment.type;
                        }
                        if(quizUid && enrichment.quiz && quizUid === enrichment.quiz.uid) {
                            setQuizEnrichmentCallback(enrichment);
                            setIsLoadingCallback(false);
                            return;
                        }
                    }
                }
            }
        }
    }
}

const isVisibleInContentMenu = async (visibleFromDate) => {
    if (!visibleFromDate) {
        return true;
    }
    return new Date().getTime() >= new Date(visibleFromDate).getTime();
}

const processAugmentations = async (augmentations, setEnrichmentsCallback, setIsLoadingCallback) => {
    let downloadedNonQuizEnrichments = [];
    let downloadedQuizEnrichments = [];
    let appConfig = AppConfig.getInstance();

    for(let augmentation of augmentations) {
        if(augmentation.enrichments) {
            for(let enrichment of augmentation.enrichments) {
                const isEnrichmentVisible = await isVisibleInContentMenu(enrichment.visibleInContentMenuFrom);
                if(enrichment.type
                    &&  isEnrichmentVisible) {
                    if( (EnrichmentType.Video === enrichment.type && appConfig.getShowVideoEnrichments())
                        || (EnrichmentType.Audio === enrichment.type && appConfig.getShowAudioEnrichments())
                        || (EnrichmentType.Link === enrichment.type && appConfig.getShowLinkEnrichments()) ) {
                        if(!enrichment.name) {
                            enrichment.name = enrichment.type;
                        }
                        if(enrichment.content) {
                            enrichment.pageNumber = augmentation.index;
                            downloadedNonQuizEnrichments.push(enrichment);
                        }
                    } else if(EnrichmentType.Quiz === enrichment.type) {
                        if(!enrichment.name) {
                            enrichment.name = enrichment.type;
                        }
                        if(appConfig.getShowQuizEnrichments()) {
                            if(enrichment.quiz) {
                                enrichment.pageNumber = augmentation.index;
                                downloadedQuizEnrichments.push(enrichment);
                            }
                        }
                    } else if(EnrichmentType.Model === enrichment.type
                                && appConfig.getShowModelEnrichments()) {
                        if(enrichment.assetBundleUid && enrichment.assetBundleUid !== MiscConstants.emptyUUID) {
                            enrichment.pageNumber = augmentation.index;
                            downloadedNonQuizEnrichments.push(enrichment);
                        }
                    }
                }
            }
        }
    }

    const downloadedEnrichments = downloadedQuizEnrichments.concat(downloadedNonQuizEnrichments);
    await downloadAssetBundleMetadatas(downloadedEnrichments, setEnrichmentsCallback, setIsLoadingCallback);
}

const processExperience = async (responseJson, setEnrichmentsCallback, setExperienceCallback, setQuizEnrichmentCallback, setIsLoadingCallback) => {
    let thumbnailImage = AppConfig.getInstance().getThumbnailAPI() + responseJson.thumbnailUid;
    let experienceMetadata = new ExperienceMetadata(responseJson.uid, thumbnailImage, responseJson.title, responseJson.publisher, responseJson.version, responseJson.isPublished);
    ExperienceState.getInstance().setSelectedExperience(experienceMetadata);
    if(setExperienceCallback) {
        setExperienceCallback(experienceMetadata);
    }
    if(setQuizEnrichmentCallback) {
        await processQuizEnrichment(responseJson.augmentations, setQuizEnrichmentCallback, setIsLoadingCallback);
    } else if(setEnrichmentsCallback) {
        await processAugmentations(responseJson.augmentations, setEnrichmentsCallback, setIsLoadingCallback);
    }
}

const downloadExperience = async (setExperienceCallback, setEnrichmentsCallback, setQuizEnrichmentCallback, setIsLoadingCallback) => {
    const getExperienceURL = AppConfig.getInstance().getExperienceAPI() + downloadExperienceUid;
    const response = await fetch(getExperienceURL).then(processStatus);
    const responseJson = await response.json();
    await processExperience(responseJson, setEnrichmentsCallback, setExperienceCallback, setQuizEnrichmentCallback, setIsLoadingCallback);
}

export const downloadExperienceAndQuiz = async (setExperienceCallback, setEnrichmentsCallback, setQuizEnrichmentCallback, setIsLoadingCallback) => {
    await downloadExperience(setExperienceCallback, setEnrichmentsCallback, setQuizEnrichmentCallback, setIsLoadingCallback);
}
