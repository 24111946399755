var outsideClickListenerHandle = null;
const clickEvent = "click";

function addAndGetOutsideClickListener(viewerParentElementId, viewerLayoutElementId, hideViewerCallback) {
    var viewerParentElement = document.getElementById(viewerParentElementId);
    var viewerLayoutElement = document.getElementById(viewerLayoutElementId);

    const outsideClickListener = event => {
        if (viewerParentElement.contains(event.target)
                && !viewerLayoutElement.contains(event.target)) {
          hideViewerCallback();
          removeClickListener();
        }
    }

    const removeClickListener = () => {
        document.removeEventListener(clickEvent, outsideClickListener);
    }

    document.addEventListener(clickEvent, outsideClickListener);

    return outsideClickListener;
}

export const setOutsideClickListener = (viewerParentElementId, viewerLayoutElementId, hideViewerCallback) => {
    removeOutsideClickListener();
    outsideClickListenerHandle = addAndGetOutsideClickListener(viewerParentElementId, viewerLayoutElementId, hideViewerCallback);
}

export const removeOutsideClickListener = () => {
    if(outsideClickListenerHandle !== null) {
        document.removeEventListener(clickEvent, outsideClickListenerHandle);
        outsideClickListenerHandle = null;
    }
}