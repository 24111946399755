import { useState, useEffect } from 'react';
import QuizViewer from '../UI/Quiz/QuizViewer';
import * as EnrichmentAPIConsumer from '../Client/EnrichmentAPIConsumer';
import * as CacheManager from '../Util/CacheManager';
import AppState from '../AppState';
import ExploreMode from '../Util/ExploreMode';

const QuizController = () => {
    const [quizEnrichment, setQuizEnrichment] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const setQuizEnrichmentCallback = (enrichment) => {
        setQuizEnrichment(enrichment);
    }

    const setIsLoadingCallback = (isLoadingValue) => {
        setIsLoading(isLoadingValue);
    }

    useEffect(() => {
        CacheManager.clearCache();
        document.body.style.background = "none";
        AppState.getInstance().setExploreMode(ExploreMode.embeddedViewerMode);
        EnrichmentAPIConsumer.downloadExperienceAndQuiz(null, null, setQuizEnrichmentCallback, setIsLoadingCallback);
    }, []);

    if (isLoading) {
        return (<div className="loading">Loading...</div>);
    } else {
        return (
            <QuizViewer
                enrichment={quizEnrichment}
                quizViewerFinishedLoadingCallback={null}
                closeQuizViewerCallback={null} />
        );
    }
};

export default QuizController;