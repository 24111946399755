import { useState, useEffect } from 'react';
import AppConfig from '../../AppConfig';
import ExperienceState from '../../State/ExperienceState';
import * as OutsideClickHandler from '../Common/OutsideClickHandler';
import * as Utilities from '../../Util/Utilities';

export default function ModelViewer({ enrichment, dynamicTimestampSuffix, displayModelViewer, fullscreenButtonCallback, closeModelViewerCallback }) {
    const viewerContentElementId = "model_viewer_content";
    const viewerParentElementId = "model_viewer_parent";
    const modelViewerLayoutElementId = "model_viewer_layout";

    const [iFrameLoading, setIFrameLoading] = useState(true);
    const [webglLoading, setWebglLoading] = useState(true);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const webglMessageSendAssetBundleUId = "sendAssetBundleUId";
    const modelViewerElementId = "model_viewer";
    const modelViewerURL = AppConfig.getInstance().getModelViewerServer();

    useEffect(() => {
        const handleWebglMessage = event => {
          setWebglLoading(false);
          if(event.origin === modelViewerURL
                && displayModelViewer) {
              if (webglMessageSendAssetBundleUId === event.data) {
                let message = getEnrichmentMessage(enrichment);
                postMessageToModelViewer(message);
              }
          }
        }

        window.addEventListener('message', handleWebglMessage, false);
        return () => window.removeEventListener("message", handleWebglMessage);
    });

    const getEnrichmentMessage = (enrichment) => {
        let messageObject = null;
        var userIdValue = Utilities.getUserId();
        var sessionIdValue = Utilities.getSessionId();
        var experienceMetadata = ExperienceState.getInstance().getSelectedExperience();
        if (experienceMetadata) {
            messageObject = {
                experienceUid: experienceMetadata.uid,
                experienceTitle: experienceMetadata.title,
                experiencePublisher: experienceMetadata.publisher,
                experienceVersion: experienceMetadata.version,
                experienceIsPublished: experienceMetadata.isPublished,
                enrichmentUid: enrichment.uid,
                userId: userIdValue,
                sessionId: sessionIdValue
            }
        } else {
            messageObject = {
                enrichmentUid: enrichment.uid,
                userId: userIdValue,
                sessionId: sessionIdValue
            }
        }

        return JSON.stringify(messageObject);
    }

    const postMessageToModelViewer = (message) => {
        document.getElementById(modelViewerElementId).contentWindow.postMessage(message, modelViewerURL);
    }

    const getModalBackgroundStyleAndPostMessage = (displayModelViewer, enrichment) => {
        if(displayModelViewer) {
            OutsideClickHandler.setOutsideClickListener(viewerParentElementId, modelViewerLayoutElementId, closeModelViewer);
            if(!webglLoading) {
                let message = getEnrichmentMessage(enrichment);
                postMessageToModelViewer(message);
            }
            return "full-height-width";
        } else {
            return "hidden-style";
        }
    }

    const onLoadingFinishedCallback = () => {
       setIFrameLoading(false);
    }

    const destroyModel = () => {
        if(!webglLoading) {
            postMessageToModelViewer("destroyModel");
        }
    }

    const resetCamera = () => {
        if(!webglLoading) {
            postMessageToModelViewer("resetCamera");
        }
    }

    const toggleLabels = () => {
        if(!webglLoading) {
            postMessageToModelViewer("toggleLabels");
        }
    }

    const changeViewerContentClass = (styleClass) => {
        var viewerContentElement = document.getElementById(viewerContentElementId);
        viewerContentElement.className = styleClass;
    }

    const showFullscreen = () => {
        changeViewerContentClass("modal-layout model-viewer-content modal-layout-fullscreen");
        setIsFullscreen(true);
        if(fullscreenButtonCallback) {
            fullscreenButtonCallback(true);
        }
    }

    const closeFullscreen = () => {
        changeViewerContentClass("modal-layout model-viewer-content");
        setIsFullscreen(false);
        if(fullscreenButtonCallback) {
            fullscreenButtonCallback(false);
        }
    }

    const getFullscreenButtonClass = () => {
        if(isFullscreen) {
            return "hidden-style";
        } else {
            return "model-viewer-action-button";
        }
    }

    const getCloseFullscreenButtonClass = () => {
        if(!isFullscreen) {
            return "hidden-style";
        } else {
            return "model-viewer-action-button";
        }
    }

    const getToggleLabelsButtonClass = () => {
        if(enrichment.modelLabels && enrichment.modelLabels.length !== 0) {
            return "model-viewer-action-button";
        } else {
            return "hidden-style";
        }
    }

    const getToggleLabelsMarginClass = () => {
        if(enrichment.modelLabels && enrichment.modelLabels.length !== 0) {
            return "content-item-filler";
        } else {
            return "hidden-style";
        }
    }

    const closeModelViewer = () => {
        OutsideClickHandler.removeOutsideClickListener();
        destroyModel();
        closeFullscreen();
        if(closeModelViewerCallback) {
            closeModelViewerCallback();
        }
    }

    return (
        <div className={getModalBackgroundStyleAndPostMessage(displayModelViewer, enrichment)}>
            <div id="model_viewer_content" className="modal-layout model-viewer-content">
                <div className="viewer-title-panel">
                    <div className="content-item viewer-close-dummy-button viewer-close-button-image">&nbsp;</div>
                    <div className="content-item-filler">&nbsp;</div>
                    <div className="content-item">
                        <img className="viewer-icon" src="./images/3d_model_icon.png" alt={enrichment.name} loading="lazy"/>
                    </div>
                    <div className="content-item overflow-text">
                        <div className="viewer-title">{enrichment.name}</div>
                    </div>
                    <div className="content-item-filler">&nbsp;</div>
                    <div className="content-item">
                        <button onClick={closeModelViewer} className="viewer-close-button">
                            <img className="viewer-close-button-image" src="./images/close_icon.png" alt="Close modal"/>
                        </button>
                    </div>
                </div>
                <div className="full-height-width model-viewer-padding">
                    {
                        iFrameLoading
                            ? (<img className="modal-spinner" src="images/loading_icon.webp" alt="Loading"/>)
                            : null
                    }
                    <iframe
                        id="model_viewer"
                        className="model-viewer-style"
                        scrolling="no"
                        onLoad={onLoadingFinishedCallback}
                        loading="eager"
                        src={modelViewerURL}
                        title="Model viewer"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                    </iframe>
                </div>
                <div className="model-viewer-button-panel">
                    <div className="content-item-filler">&nbsp;</div>
                    <div className="content-item">
                        <div className="model-viewer-action-button" onClick={resetCamera}>
                            <img className="image" src="./images/reset_icon.png" alt="Reset camera" loading="lazy"/>
                            <div className="text">Reset camera</div>
                        </div>
                    </div>
                    <div className="content-item-filler">&nbsp;</div>
                    <div className="content-item">
                        <div className={getFullscreenButtonClass()} onClick={showFullscreen}>
                            <img className="image" src="./images/fullscreen_icon.png" alt="Fullscreen" loading="lazy"/>
                            <div className="text">Fullscreen</div>
                        </div>
                        <div className={getCloseFullscreenButtonClass()} onClick={closeFullscreen}>
                            <img className="image" src="./images/close_fullscreen_icon.png" alt="Close fullscreen" loading="lazy"/>
                            <div className="text">Close fullscreen</div>
                        </div>
                    </div>
                    <div className="content-item-filler">&nbsp;</div>
                    <div className="content-item">
                        <div className={getToggleLabelsButtonClass()} onClick={toggleLabels}>
                             <img className="image" src="./images/toggle_labels_icon.png" alt="Toggle labels" loading="lazy"/>
                             <div className="text">Toggle labels</div>
                         </div>
                    </div>
                    <div className={getToggleLabelsMarginClass()}>&nbsp;</div>
                </div>
            </div>
        </div>
    );
}