import React from 'react';
import AppController from './Controller/AppController';
import './App.css';

function App() {
  return (
    <div className="full-height-width">
      <AppController />
    </div>
  );
}

export default App;