import EnrichmentType from '../../Util/EnrichmentType';
import * as Utilities from '../../Util/Utilities';

export default function EnrichmentRow({ enrichment, keyIndex, showModelViewerCallback, showMediaPlayerCallback, openURLCallback, showQuizViewerCallback }) {
    const getEnrichmentButtonStyle = (buttonType, enrichmentType) => {
        if(buttonType === enrichmentType) {
            return "action-button";
        } else {
            return "hidden-style";
        }
    }

    return (
        <li key={keyIndex}>
            <article className="card">
                <div className="content-item">
                    <div className="card-image">
                        <img src={Utilities.getEnrichmentIcon(enrichment.type)} alt={enrichment.name} loading="lazy"/>
                    </div>
                </div>
                <div className="content-item-filler">
                    <div className="card-text">
                        <div className="content-item"><div className="card-title">{enrichment.name}</div></div>
                        <div className="content-item"><div className="card-subText">Page {enrichment.pageNumber}</div></div>
                    </div>
                </div>
                <div className="content-item">
                    <div className={getEnrichmentButtonStyle(EnrichmentType.Model, enrichment.type)} onClick={() => showModelViewerCallback(enrichment)}>
                        <div className="text">3D</div>
                    </div>
                    <div className={getEnrichmentButtonStyle(EnrichmentType.Link, enrichment.type)} onClick={() => openURLCallback(enrichment)}>
                        <div className="text">Open</div>
                    </div>
                    <div className={getEnrichmentButtonStyle(EnrichmentType.Video, enrichment.type)} onClick={() => showMediaPlayerCallback(enrichment)}>
                        <div className="text">Play</div>
                    </div>
                    <div className={getEnrichmentButtonStyle(EnrichmentType.Audio, enrichment.type)} onClick={() => showMediaPlayerCallback(enrichment)}>
                        <div className="text">Play</div>
                    </div>
                    <div className={getEnrichmentButtonStyle(EnrichmentType.Quiz, enrichment.type)} onClick={() => showQuizViewerCallback(enrichment)}>
                        <div className="text">Open quiz</div>
                    </div>
                </div>
            </article>
        </li>
    );
}