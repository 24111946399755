import { useState, useEffect } from 'react';
import ErrorDialogWrapper from '../ErrorDialog/ErrorDialogWrapper';
import QuizStart from './QuizStart';
import QuizQuestion from './QuizQuestion';
import QuizComplete from './QuizComplete';
import * as AnalyticsManager from '../../Analytics/AnalyticsManager';

export default function QuizViewer({ enrichment, quizViewerFinishedLoadingCallback, closeQuizViewerCallback }) {
    const [displayError, setDisplayError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [hasQuizStarted, setHasQuizStarted] = useState(false);
    const [hasQuizCompleted, setHasQuizCompleted] = useState(false);
    const [currentQuestionNum, setCurrentQuestionNum] = useState(1);
    const [currentQuestion, setCurrentQuestion] = useState();
    const [numOfCorrectAnswers, setNumOfCorrectAnswers] = useState(0);

    const quizQuestions = enrichment.quiz.quizQuestions;
    const totalNumOfQuestions = enrichment.quiz.quizQuestions.length;

    useEffect(() => {
        if(currentQuestionNum <= totalNumOfQuestions) {
            setCurrentQuestion(quizQuestions[currentQuestionNum - 1]);
        }
    }, [currentQuestionNum, totalNumOfQuestions, quizQuestions, currentQuestion]);

    const shuffleOptions = (options) => {
        for (let i = options.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [options[i], options[j]] = [options[j], options[i]];
        }
    }

    const showErrorDialog = (message) => {
        setErrorMessage(message);
        setDisplayError(true);
    }

    const hideErrorDialogCallback = () => {
        setDisplayError(false);
    }

    const onLoadingFinishedCallback = () => {
       if(quizViewerFinishedLoadingCallback) {
            quizViewerFinishedLoadingCallback();
       }
    }

    const restartQuizCallBack = () => {
        setHasQuizCompleted(false);
        setHasQuizStarted(false);
    }

    const startQuizCallBack = () => {
        if(totalNumOfQuestions === 0) {
            showErrorDialog("The quiz doesn't have any questions added");
        } else {
            AnalyticsManager.logQuizStartedEvent(enrichment.uid, enrichment.quiz.uid);
            for(let i = 0; i < quizQuestions.length; i++) {
                shuffleOptions(quizQuestions[i].options);
            }
            setNumOfCorrectAnswers(0);
            setCurrentQuestionNum(1);
            setHasQuizStarted(true);
        }
    }

    const optionSelectedCallback = (questionUid, isOptionCorrect) => {
        AnalyticsManager.logQuizQuestionAnsweredEvent(enrichment.uid, enrichment.quiz.uid, questionUid);
        if(isOptionCorrect === true) {
            setNumOfCorrectAnswers(numOfCorrectAnswers + 1);
        }
        if(currentQuestionNum === totalNumOfQuestions) {
            AnalyticsManager.logQuizCompletedEvent(enrichment.uid, enrichment.quiz.uid);
            setHasQuizStarted(false);
            setHasQuizCompleted(true);
        } else {
            setCurrentQuestionNum(currentQuestionNum + 1);
        }
    }

    const getStyleClass = () => {
        if(closeQuizViewerCallback === null) {
            return "full-height-width allow-autoscroll";
        } else {
            return "full-height-width";
        }
    }

    return (
        <div className={getStyleClass()}>
            {
                displayError
                    ? (<ErrorDialogWrapper
                        errorMessage={errorMessage}
                        hideErrorDialogCallback={hideErrorDialogCallback} />)
                    : null
            }
            {
                hasQuizCompleted
                    ? (<QuizComplete
                        numOfCorrectAnswers={numOfCorrectAnswers}
                        totalNumOfQuestions={totalNumOfQuestions}
                        closeQuizViewerCallback={closeQuizViewerCallback}
                        restartQuizCallBack={restartQuizCallBack}
                        onLoadingFinishedCallback={onLoadingFinishedCallback} />)
                    :  hasQuizStarted
                        ? (<QuizQuestion
                            quizQuestion={currentQuestion}
                            currentQuestionNum={currentQuestionNum}
                            totalNumOfQuestions={totalNumOfQuestions}
                            optionSelectedCallback={optionSelectedCallback}
                            closeQuizViewerCallback={closeQuizViewerCallback}
                            onLoadingFinishedCallback={onLoadingFinishedCallback} />)
                        : (<QuizStart
                            quiz={enrichment.quiz}
                            startQuizCallBack={startQuizCallBack}
                            closeQuizViewerCallback={closeQuizViewerCallback}
                            onLoadingFinishedCallback={onLoadingFinishedCallback} />)
            }
        </div>
    );
}