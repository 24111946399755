export default function ErrorDialog({ errorMessage, errorDialogFinishedLoadingCallback, closeErrorDialogCallback }) {
    const onErrorDialogLoadingFinishedCallback = () => {
       if(errorDialogFinishedLoadingCallback) {
           errorDialogFinishedLoadingCallback();
       }
    }

    return (
        <div className="modal-layout error-dialog-content">
            <div className="viewer-title-panel">
                <div className="content-item-filler">&nbsp;</div>
                <div className="content-item">
                    <button onClick={closeErrorDialogCallback} className="viewer-close-button">
                        <img className="viewer-close-button-image" src="./images/close_icon.png" alt="Close modal" onLoad={onErrorDialogLoadingFinishedCallback}/>
                    </button>
                </div>
            </div>
            <div className="content-item-filler">&nbsp;</div>
            <div className="content-item error-dialog-text">{errorMessage}</div>
            <div className="content-item-filler">&nbsp;</div>
        </div>
    );
}