import * as OutsideClickHandler from '../Common/OutsideClickHandler';
import ErrorDialog from './ErrorDialog';

export default function ErrorDialogWrapper({ errorMessage, hideErrorDialogCallback }) {
    const errorDialogParentElementId = "error_dialog_parent";
    const errorDialogLayoutElementId = "error_dialog_layout";

    const errorDialogFinishedLoadingCallback = () => {
        OutsideClickHandler.setOutsideClickListener(errorDialogParentElementId, errorDialogLayoutElementId, hideErrorDialogCallback);
    }

    const closeErrorDialogCallback = () => {
        OutsideClickHandler.removeOutsideClickListener();
        if(hideErrorDialogCallback) {
            hideErrorDialogCallback();
        }
    }

    return (
        <div id="error_dialog_parent" className="modal-background">
            <div id="error_dialog_layout" className="error-dialog-layout">
                <ErrorDialog
                    errorMessage={errorMessage}
                    errorDialogFinishedLoadingCallback={errorDialogFinishedLoadingCallback}
                    closeErrorDialogCallback={closeErrorDialogCallback} />
            </div>
       </div>
    );
}