export default function QuizStart({ quiz, startQuizCallBack, closeQuizViewerCallback, onLoadingFinishedCallback }) {
    return (
        <div className="modal-layout quiz-viewer-content">
            {
                closeQuizViewerCallback === null
                    ? null
                    : (<div className="content-item">
                          <button onClick={closeQuizViewerCallback} className="viewer-close-button">
                              <img src="images/close_icon.png" className="viewer-close-button-image" alt="Close modal" onLoad={onLoadingFinishedCallback}/>
                          </button>
                      </div>)
            }

            <div className="content-item"><div className="quiz-large-text max-height-fit">{quiz.name}</div></div>
            <div className="content-item"><div className="text">{quiz.quizQuestions.length} questions</div></div>
            <div className="content-item-filler">&nbsp;</div>
            <div className="content-item">
                <div className="quiz-button" onClick={startQuizCallBack}>
                    <div className="text">Start quiz</div>
                </div>
            </div>
        </div>
    );
}